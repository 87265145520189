<template>
  <a-row :gutter="16">
    <a-col :sm="24" :lg="12" :xl="8" :xxl="6" style="margin-bottom: 16px">
      <a-page-header :ghost="false">
        <template slot="extra">
          <a @click="$refs.updateForm.show(detail)" style="color: #faad14">
            <a-space>
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <div style="margin: 24px 0; text-align: center">
          <a-icon
            :component="component"
            style="font-size: 128px; vertical-align: middle; margin-top: -4px;"
          ></a-icon>
          <div style="font-size: 32px; font-weight: bold">
            {{ detail.nickname }}
          </div>
          <div style="font-size: 16px; color: rgba(0, 0, 0, 0.25)">
            {{ detail.username }}
          </div>
        </div>
        <a-descriptions :column="1">
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="phone"></a-icon>
              <span>手机号码</span>
            </a-space>
            <div>{{ detail.phone_number }}</div>
          </a-descriptions-item>
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="mail"></a-icon>
              <span>邮箱地址</span>
            </a-space>
            <div>{{ detail.email_address }}</div>
          </a-descriptions-item>
          <a-descriptions-item>
            <a-space slot="label">
              <a-icon type="hourglass" />
              <span>注册时间</span>
            </a-space>
            <div>{{ detail.created_at }}</div>
          </a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </a-col>

    <a-col :sm="24" :lg="12" :xl="16" :xxl="18">
      <a-card :body-style="{ paddingTop: 0 }" :bordered="false">
        <a-tabs>
          <a-tab-pane key="welcome" tab="欢迎">
            <welcome-tab style="max-width: 720px"></welcome-tab>
          </a-tab-pane>
          <a-tab-pane key="password" tab="密码修改">
            <password-tab style="max-width: 720px"></password-tab>
          </a-tab-pane>
        </a-tabs>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { getUserProfile } from '@/api/user'
import PasswordTab from './modules/PasswordTab.vue'
import UpdateForm from './modules/UpdateForm.vue'
import WelcomeTab from './modules/WelcomeTab.vue'

export default {
  name: 'UserProfile',
  components: {
    PasswordTab,
    UpdateForm,
    WelcomeTab
  },
  data () {
    return {
      detail: {
        username: '',
        nickname: '',
        phone_number: '',
        email_address: '',
        created_at: ''
      },
      component: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
            <path d="M512 754.5c131.8 0 250.4 56.4 333 146.4C954.6 807 1024 667.6 1024 512 1024 229.2 794.8 0 512 0S0 229.2 0 512c0 155.6 69.4 295 179 388.9 82.6-90 201.2-146.4 333-146.4z" fill="#ECEDEF" p-id="57694"></path><path d="M593.8 761.9v20c0 45-36.8 81.8-81.8 81.8s-81.8-36.8-81.8-81.8v-20c-98.6 18-186.1 68.1-251.2 139 89.5 76.7 205.8 123.1 333 123.1 127.1 0 243.5-46.4 333-123.1-65.1-70.9-152.6-120.9-251.2-139z" fill="#57ABE4" p-id="57695"></path><path d="M512 863.7c45 0 81.8-36.8 81.8-81.8v-20c-26.5-4.9-53.9-7.4-81.8-7.4s-55.3 2.6-81.8 7.4v20c0 45 36.8 81.8 81.8 81.8z" fill="#C6CBD5" p-id="57696"></path><path d="M730.9 600.4H293.1c-14.3 0-26-11.7-26-26V239.2c0-14.3 11.7-26 26-26h437.7c14.3 0 26 11.7 26 26v335.2c0.1 14.3-11.6 26-25.9 26z" fill="#4197DF" p-id="57697"></path><path d="M298.5 535.8m-72.9 0a72.9 72.9 0 1 0 145.8 0 72.9 72.9 0 1 0-145.8 0Z" fill="#C6CBD5" p-id="57698"></path><path d="M725.5 535.8m-72.9 0a72.9 72.9 0 1 0 145.8 0 72.9 72.9 0 1 0-145.8 0Z" fill="#C6CBD5" p-id="57699"></path><path d="M607.3 307.5C581.1 321 548 329.1 512 329.1s-69.1-8.1-95.3-21.6c-65 33-109.8 100.5-109.8 178.1V569c0 109.8 89.8 199.6 199.6 199.6h11.1c109.8 0 199.6-89.8 199.6-199.6v-83.4c0-77.6-44.9-145.1-109.9-178.1z" fill="#FFFFFF" p-id="57700"></path><path d="M346.5 569l-5.8-83.4s-5.4-60.4 3.8-116.4c-23.7 32.8-37.7 73-37.7 116.4V569c0 109.8 89.8 199.6 199.6 199.6h11.1c4.8 0 9.6-0.2 14.3-0.6-103.1-7.4-185.3-94.1-185.3-199z" fill="#ECEDEF" p-id="57701"></path><path d="M660.3 243.6c0 45.4-66.4 82.2-148.3 82.2S363.7 289 363.7 243.6s66.4-82.2 148.3-82.2c39.5 0 148.3-20.4 148.3-20.4v102.6z" fill="#4197DF" p-id="57702"></path>
          </svg>
        `
      }
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getUserProfile().then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>
