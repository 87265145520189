var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"confirm-loading":_vm.loading,"title":"个人信息编辑","visible":_vm.visible,"width":720},on:{"ok":_vm.ok,"cancel":_vm.reset}},[_c('a-form-model',{ref:"form",attrs:{"label-col":{
      span: 4,
      style: {
        textAlign: 'left'
      }
    },"model":_vm.form,"rules":_vm.rules,"wrapper-col":{
      span: 20
    }}},[_c('a-form-model-item',{attrs:{"label":"姓名","prop":"nickname"}},[_c('a-input',{attrs:{"max-length":128,"placeholder":"请输入姓名"},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", $$v)},expression:"form.nickname"}})],1),_c('a-form-model-item',{attrs:{"label":"电话号码","prop":"phoneNumber"}},[_c('a-input',{attrs:{"max-length":11,"placeholder":"请输入电话号码"},model:{value:(_vm.form.phoneNumber),callback:function ($$v) {_vm.$set(_vm.form, "phoneNumber", $$v)},expression:"form.phoneNumber"}})],1),_c('a-form-model-item',{attrs:{"label":"邮箱地址","prop":"emailAddress"}},[_c('a-input',{attrs:{"max-length":128,"placeholder":"请输入邮箱地址"},model:{value:(_vm.form.emailAddress),callback:function ($$v) {_vm.$set(_vm.form, "emailAddress", $$v)},expression:"form.emailAddress"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }