<template>
   <div style="min-height: 360px;">
    <div style="margin: 120px 0; font-size: 32px; font-weight: 600;">
        欢迎使用 {{ title }}
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'WelcomeTab',
  data () {
    return {
      title: config.title
    }
  }
}
</script>
