<template>
  <a-form-model
    ref="form"
    :label-col="{
      span: 6,
      style: {
        textAlign: 'left'
      }
    }"
    :model="form"
    :rules="rules"
    :wrapper-col="{
      span: 18
    }"
  >
    <a-form-model-item label="原密码" prop="passwordOld">
      <a-input-password
        v-model="form.passwordOld"
        autocomplete="new-password"
        :max-length="128"
        placeholder="请输入原密码"
      ></a-input-password>
    </a-form-model-item>
    <a-form-model-item label="新密码" prop="passwordNew">
      <a-input-password
        v-model="form.passwordNew"
        autocomplete="new-password"
        :max-length="128"
        placeholder="请输入新密码"
      ></a-input-password>
    </a-form-model-item>
    <a-form-model-item label="确认密码" prop="passwordConfirm">
      <a-input-password
        v-model="form.passwordConfirm"
        autocomplete="new-password"
        :max-length="128"
        placeholder="请输入确认密码"
      ></a-input-password>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 20, offset: 4 }">
      <a-space size="middle">
        <a-button :loading="loading" type="primary" @click="ok">确定</a-button>
        <a-button @click="clear">重置</a-button>
      </a-space>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { updatePassword } from '@/api/user'
import { encrypt } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'PasswordTab',
  data () {
    return {
      form: {
        passwordOld: '',
        passwordNew: '',
        passwordConfirm: ''
      },
      rules: {
        passwordOld: [
          {
            message: '请输入原密码',
            required: true,
            trigger: 'blur'
          },
          {
            message: '请输入至少 6 位密码',
            min: 6,
            trigger: 'blur'
          }
        ],
        passwordNew: [
          {
            message: '请输入新密码',
            required: true,
            trigger: 'blur'
          },
          {
            message: '请输入至少 6 位密码',
            min: 6,
            trigger: 'blur'
          },
          {
            validator: (_, value, callback) => {
              if (value === this.form.passwordOld) {
                callback(new Error('新密码与原密码相同'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        passwordConfirm: [
          {
            message: '请输入确认密码',
            required: true,
            trigger: 'blur'
          },
          {
            message: '请输入至少 6 位密码',
            min: 6,
            trigger: 'blur'
          },
          {
            validator: (_, value, callback) => {
              if (value !== this.form.passwordNew) {
                callback(new Error('两次输入的密码不一致'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters(['publicKey'])
  },
  methods: {
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            password_old: encrypt(this.publicKey, this.form.passwordOld),
            password_new: encrypt(this.publicKey, this.form.passwordNew)
          }
          updatePassword(data)
            .then(res => {
              this.$message.success(res.message)
              this.$refs.form.resetFields()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    clear () {
      this.$refs.form.resetFields()
      this.loading = false
    }
  }
}
</script>
